const getSuccessFail = (str: string) => {
  return {
    success: `${str.toUpperCase()  }_SUCCESS`,
    fail: `${str.toUpperCase()  }_FAIL`,
    loading: `${str.toUpperCase()  }_LOADING`,
  };
};

const type = {
  admin: {
    pincodes: {
      create: getSuccessFail('PINCODE_CREATE'),
      update: getSuccessFail('PINCODE_UPDATE'),
      delete: getSuccessFail('PINCODE_DELETE')
    },
    offers: {
      create: getSuccessFail('OFFERS_CREATE'),
      update: getSuccessFail('OFFERS_UPDATE'),
      delete: getSuccessFail('OFFERS_DELETE')
    },
    technicians: {
      allot: getSuccessFail('TECHNICIANS_ALLOT'),
      assign: getSuccessFail('TECHNICIANS_ASSIGN')
    },
    ancexpenses: {
      create: getSuccessFail('ANCEXPENSES_CREATE'),
      update: getSuccessFail('ANCEXPENSES_UPDATE'),
      delete: getSuccessFail('ANCEXPENSES_DELETE')
    }
  },
  auth: {
    signIn: getSuccessFail('SIGNIN'),
    signOut: getSuccessFail('SIGNOUT'),
    signUp: getSuccessFail('SIGNUP'),
    tutorialsAuth: getSuccessFail('TUTORIALS'),
    otp: {
      send: getSuccessFail('OTP_SEND'),
      verify: {
        ...getSuccessFail('OTP_VERIFY'),
        wrongOtp: 'OTP_VERIFY_INCORRECT'
      }
    },
    withProvider: {
      ...getSuccessFail('SIGNIN_WITHPROVIDER'),
      relogin: 'SIGNIN_WITHPROVIDER_RELOGIN'
    }
  },
  ims: {
    admin: {
      create: getSuccessFail('IMSADMIN_CREATE'),
      update: getSuccessFail('IMSADMIN_UPDATE'),
      delete: getSuccessFail('IMSADMIN_DELETE')
    },
    create: getSuccessFail('IMS_TICKET')
  },
  order: {
    create: {
      ...getSuccessFail('ORDER_CREATE'),
      emailFail: 'ORDER_CREATE_EMAIL_ERR'
    },
    update: {
      ...getSuccessFail('ORDER_UPDATE'),
      emailFail: 'ORDER_UPDATE_EMAIL_ERR'
    },
    delete: getSuccessFail('ORDER_DELETE')
  },
  profile: {
    edit: getSuccessFail('EDITPROFILE'),
    link: getSuccessFail('LINKPROFILE')
  }
};

export default type;