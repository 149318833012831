import { ExtendedFirestoreInstance } from "react-redux-firebase";
import type from "../actionType";

interface componentInterface {

}
interface objInterface {
  components: componentInterface
}
export interface createIMSTicketInterface {
  orderId: string,
  itemTickets: objInterface
}

const createIMSTicket = (imsTicketProps, getEnvironment: boolean, successCallback) => {
  const { orderId, itemTickets } = imsTicketProps;
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore: ExtendedFirestoreInstance = getFirestore();
    const profile = getState().firebase.profile;
    const inventoryBatch = firestore.batch();

    const authId = getState().firebase.auth.uid;
    Object.entries(itemTickets).map(([id, obj]:any) => {
      // For every component in the list, reduce X from its inventory list, where X is the number of components used in this order.
      obj.components.map((element: any) => {
        // Allowing componentQty to go negative: technician shouldnt care about it.
        inventoryBatch.update(firestore.collection('inventory').doc(element.id), {
          lastusername: profile.firstName + ' ' + profile.lastName,
          componentQty: firestore.FieldValue.increment(-element.componentQty),
          timestamp: new Date()
        });
      });
      inventoryBatch.set(firestore.collection('inventoryTickets').doc(), {
        orderId,
        itemId: id,
        itemName: obj.itemName,
        // TODO Filter out MaxQuantity
        componentsUsed: obj.components,
        db: getEnvironment,
        techName: profile.firstName + ' ' + profile.lastName,
        techAuthID: authId,
        timestamp: new Date()
      });
    });

    inventoryBatch
      .commit()
      .then(() => {
        const quoteRef = firestore.collection('quotes').doc(orderId);
        firestore
          .runTransaction((transaction) => {
            return transaction.get(quoteRef).then((refDoc) => {
              if (refDoc.exists) {
                const itemsArr = refDoc.data()?.items;
                Object.entries(itemTickets).map(([id, obj]: any) => {
                  if (!itemsArr[id].price) {
                    itemsArr[id].price = 0;
                  }
                  if (!itemsArr[id].repprice) {
                    itemsArr[id].repprice = 0;
                  }
                  if (!itemsArr[id].discount) {
                    itemsArr[id].discount = 0;
                  }
                  itemsArr[id].price += obj.components.reduce((names, item) => {
                    return names + parseFloat(item.componentPrice) * parseInt(item.componentQty);
                  }, 0);
                  // Repair Price (repprice, AKA Service charges) are a product of quantity of item and service charges applied.
                  itemsArr[id].repprice += obj.service * parseInt(obj.quantity);
                  itemsArr[id].discount += obj.discount * parseInt(obj.quantity);
                  transaction.update(quoteRef, {
                    items: itemsArr,
                    lastChanged: new Date()
                  });
                });
                return itemsArr;
              }
            });
          })
          .then(() => {
            successCallback();
            return dispatch({ type: type.ims.create.success });
          })
          .catch((err) => {
            dispatch({ type: type.ims.create.fail, payload: err });
          });
      })
      .catch((err) => {
        dispatch({ type: type.ims.create.fail, payload: err });
      });
  };
};

export default createIMSTicket;