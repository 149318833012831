import type from './actionType';
import addPincode from './admin/addPincode';
import ancExpensesAddUpdate from './admin/addUpdateAncillaryExp';
import offersAddUpdate from './admin/addUpdateOffers';
import techniciansAllot from './admin/allotTechnicians';
import techniciansAssign from './admin/assignTechnician';
import ancExpensesDelete from './admin/deleteAncillaryExp';
import offerDelete from './admin/deleteOffers';
import deletePincode from './admin/deletePincode';
import sendOtp from './auth/sendOtp';
import signIn from './auth/signIn';
import signInWithProvider from './auth/signInWithProvider';
import signOut from './auth/signOut';
import signUp from './auth/signUp';
import tutorialsAuth from './auth/tutorialsAuth';
import verifyOtp from './auth/verifyOtp';
import createAdminIMS from './ims/createAdminIms';
import createIMSTicket from './ims/createImsTicket';
import deleteAdminIMS from './ims/deleteAdminIms';
import createOrder from './order/createOrder';
import deleteOrder from './order/deleteOrder';
import UpgradeOrder from './order/upgradeOrder';
import editProfile from './profile/editProfile';
import linkNumber from './profile/linkNumber';

const admin = {
  pincodes: {
    create: addPincode,
    update: addPincode,
    delete: deletePincode
  },
  offers: {
    create: offersAddUpdate,
    update: offersAddUpdate,
    delete: offerDelete
  },
  technicians: {
    allot: techniciansAllot,
    assign: techniciansAssign
  },
  ancexpenses: {
    create: ancExpensesAddUpdate,
    update: ancExpensesAddUpdate,
    delete: ancExpensesDelete
  }
};
const auth = {
  otp: {
    send: sendOtp,
    verify: verifyOtp
  },
  signIn,
  signOut,
  signUp,
  withProvider: signInWithProvider,
  tutorialsAuth
};
const ims = {
  admin: {
    create: createAdminIMS,
    delete: deleteAdminIMS
  },
  create: createIMSTicket
};

const orders = {
  create: createOrder,
  update: UpgradeOrder,
  delete: deleteOrder
};

const profile = {
  edit: editProfile,
  link: linkNumber
};

const redux = {
  reduxActions: {
    admin,
    auth,
    ims,
    orders,
    profile
  },
  reduxTypes: type, 
};

export default redux;
