import type from "../actionType";

export interface signInInterface {
  email: string,
  password: string
}


const signIn = (credentials: signInInterface,successCallback) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        successCallback();
        dispatch({ type: type.auth.signIn.success });
      })
      .catch((err) => {
        dispatch({ type: type.auth.signIn.fail, payload: err });
      });
  };
};
export default signIn;