
export const benefits = [
    {
        img: "@twzzr/support/dist/assets/images/receive_icon.png",
        title: "Doorstep service available",
    },
    {
        img: "@twzzr/support/dist/assets/images/badge_icon.png",
        title: "Upto 1 Year warranty on repair",
    },
    {
        img: "@twzzr/support/dist/assets/images/best-price_icon.png",
        title: "Best Price with value added",
    },
    {
        img: "@twzzr/support/dist/assets/images/guarantee_icon.png",
        title: "100% genuine parts with warranty",
    },
];

export const servicesOffered = [
    {
        img: "@twzzr/support/dist/assets/images/ford_car.png",
        title: "Ride on Car/Bike",
    },
    {
        img: "@twzzr/support/dist/assets/images/drone.png",
        title: "Drone",
    },
    {
        img: "@twzzr/support/dist/assets/images/rc_truck_heli.png",
        title: "RC Cars",
    },
    {
        img: "@twzzr/support/dist/assets/images/piano.png",
        title: "Others",
    },
];

export const faqs = [
    {
        question: "How many months warranty does Twizzr provide?",
        ans:
            "Warranty on Ride on Toys is upto 12 Months. \nWarranty on Small Remote Control Toys is upto 3 Months.",
    },
    {
        question: "How much is the Service Charge?",
        ans:
            "Service Charge of small Remote Control Toys is Rs. 49/- (Exclusive of components cost if any).\nService charge of Ride on Car/Bike is Rs. 349/- (Exclusive of components cost if any)\nFor doorstep service delivery charges for all visits is fixed at Rs. 600/-\n\nNote: Door step visit is only available for Ride on Toys.",
    },
    {
        question: "How many days does it take for repair?",
        ans:
            "Ideally it take 2 working days to get the items repaired.\nFor retail and wholesale customers, bulk repairs may take upto 7 working days.",
    },
    {
        question: "How do we ascertain that the parts are genuine and price are fair?",
        ans:
            "Technicians will inspect the details in front of you and generate the quote, also the details on every part is shared with the customer.\nAll the parts carries warranty whose quality is tested before it goes out of inventory.",
    },
];
