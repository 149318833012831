import axios from 'axios';
import React from 'react';
import dayjs from 'dayjs';
import { orderItems } from '../../interfaces.js';
import OrderStatus from '../../managers/orderStatus.js';

import emailService from '../../services/email.js';
import slackMessage from '../../services/slackMessage.js';
import variables from '../../var.js';
import type from '../actionType';
import { ExtendedFirebaseInstance, ExtendedFirestoreInstance } from 'react-redux-firebase';

export interface ordersInterface {
  authId: number,
  billNum: number | undefined,
  createdAt: Date,
  db: string,
  email: string,
  error: string,
  firstName: string,
  image: string,
  items: orderItems[],
  lastName: number,
  message: string,
  mobile: string,
  onBehalfOf: boolean,
  orderID: string,
  product: string,
  quantity: string,
  shop: string,
  status: string,
  targetDate: Date,
  technicianName: string | null,
  allotedTo?: string
}

const scanImagesAndUploadToFS = (firebase:ExtendedFirebaseInstance, order: ordersInterface, billNo:string) => {
  const storageRef = firebase.storage().ref();
  return new Promise((resolve, reject) => {
    const recursive = (index, callback) => {
      if (index >= order.items.length) {
        return resolve(JSON.parse(JSON.stringify(order)));
      }
      // #twzzr-web/109: Is this is the right solution for it? 
      if (order.items[index].image !== '' && order.items[index].image && order.items[index].image.file) {
        console.log(order.items[index]);
        const upload = storageRef
          .child("orders/" + billNo + "-" + (index + 1))
          .put(order.items[index].image.file, {
            contentType: order.items[index].image.file.type
          });
        upload.on(
          'state_changed',
          () => { },
          () => { },
          () => {
            upload.snapshot.ref.getDownloadURL().then((downloadURL) => {
              order.items[index]['image'] = downloadURL;
              console.log('Download', order.items[index]['image']);
              // Resolve if item is last.
              return callback(index + 1, callback);
            });
          }
        );
      } else {
        return callback(index + 1, callback);
      }
    };
    try {
      recursive(0, recursive);
    } catch (err) {
      reject(err);
    }
  });
};

/**
 * Create an order
 * @param order Order information
 * @param billno Bill (TWX001)
 * @param getEnvironment (dev or prod)
 * @param successCallback Function to run if successful (useful for redirecting)
 * @returns 
 */
const createOrder = (order: ordersInterface, billno: string, getEnvironment: string, successCallback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore: ExtendedFirestoreInstance = getFirestore();
    const firebase: ExtendedFirebaseInstance = getFirebase();
    const profile = getState().firebase.profile;
    const authId = getState().firebase.auth.uid;

    if (billno === 'TWA000' || billno === 'DTWA000') {
      return dispatch({
        type: type.order.create.fail,
        payload: "Invalid Bill Number " + billno
      });
    }

    firestore.collection('quotes').doc(billno).get()
      .then((doc) => {
        if (doc.exists) {
          dispatch({
            type: type.order.create.fail,
            payload: "Repeated bill ID: " + billno + " was used for order number" + doc.id
          });
        } else {
          console.log('Working with', billno);
          scanImagesAndUploadToFS(firebase, order, billno).then(
            ({
              customerFirstName,
              customerLastName,
              deliveryAddress,
              customerEmail,
              technicianName,
              ...res
            }) => {
              const relevantEmail = order.onBehalfOf ? customerEmail : profile.email;
              const relevantFirstName = order.onBehalfOf ? customerFirstName : profile.firstName;
              const relevantLastName = order.onBehalfOf ? customerLastName : profile.lastName;
              const relevantAddress = order.onBehalfOf ? deliveryAddress : profile.shop;
              const obj = {
                firstName: relevantFirstName,
                lastName: relevantLastName,
                authId,
                email: relevantEmail,
                mobile: order.mobile,
                shop: relevantAddress,
                createdAt: new Date(),
                lastChanged: new Date(),
                db: getEnvironment,
                items: order.items,
                status: OrderStatus.getFirstStage(),
                onBehalfOf: res.onBehalfOf || false,
                technicianName: res.technicianName || null
              };

              // Any OBO order is assigned to the same technician.
              if (res.onBehalfOf === true) {
                obj["allotedTo"] = authId;
              }

              firestore
                .collection('quotes')
                .doc(billno)
                .set(obj)
                .then(() => {
                  const billNumber = parseInt(billno.slice(billno.length - 3));
                  const billCharacterMultiple =
                    billno.substr(billno.length - 4)[0].charCodeAt(0) - 65;
                  const newValues = {};
                  newValues[`${getEnvironment}QuoteId`] =
                    billNumber + billCharacterMultiple * 1000;
                  console.log(
                    `Setting ${getEnvironment}Order counter to`,
                    billNumber + billCharacterMultiple * 1000
                  );

                  firestore
                    .collection('static')
                    .doc('lastQuote')
                    .update(newValues)
                    .then(() => {
                      if (getEnvironment === "prod") {
                        const itemInformation: any = [];
                        order.items.map((item) => {
                          const temp = {
                            type: "section",
                            text: {
                              type: "mrkdwn",
                              text: "*" + item.product + "*\nQty: " + item.quantity
                            }
                          };
                          if (item.image) {
                            temp["accessory"] = {
                              type: "image",
                              image_url: item.image,
                              alt_text: "computer thumbnail"
                            };
                          }
                          itemInformation.push(temp);
                        });

                        const blockArr = [{
                          type: "header",
                          text: {
                            type: "plain_text",
                            text: `:new: New Service Order ${billno} :new:`,
                          },
                        },
                        {
                          type: "section",
                          text: {
                            type: "mrkdwn",
                            text: `:standing_person: *Name*: ${relevantFirstName} ${relevantLastName} ${technicianName ? `(Order on behalf of customer by *${technicianName}*)` : ""}
                              :email: *Email*: ${relevantEmail} 
                              :iphone: *Phone*: <tel:${order.mobile}|${order.mobile}> 
                              :spider_web: *Web Location*: ${location}
                              `
                          }
                        },
                        {
                          type: "divider",
                        },
                        ...itemInformation,
                        { type: "divider" },
                        {
                          type: "actions",
                          elements: [
                            {
                              "type": "button",
                              "text": {
                                "type": "plain_text",
                                "emoji": true,
                                "text": ":speech_balloon: Contact on Whatsapp"
                              },
                              "value": "click_me_002",
                              "url": `https://wa.me/${order.mobile}/?text=Hey, I'm from Team Twizzr. I wanted to inform you about your order ${billno}`,
                              "action_id": "button-action-001"
                            },
                            {
                              type: "button",
                              text: {
                                type: "plain_text",
                                emoji: true,
                                text: ":pushpin: View Order Details",
                              },
                              value: "click_me_002",
                              url: `https://twizzr.com/orders/${billno}`,
                              "action_id": "button-action-002",
                            }
                          ]
                        }];
                        slackMessage("https://hooks.slack.com/services/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", JSON.stringify({
                          blocks: blockArr,
                        }));

                        emailService({
                          apiAuth: variables.NodeJS.apiKey,
                          tag: 'ORDER_UPDATE',
                          email: order.onBehalfOf
                            ? customerEmail
                            : profile.email,
                          firstName: order.onBehalfOf
                            ? customerFirstName
                            : profile.firstName,
                          billId: billno,
                          orderStatus: OrderStatus.getFirstStage(),
                          itemsList: order.items,
                          createdAt: dayjs().format('MMM D YYYY')
                        });
                      }
                    });
                })
                .then((res3) => {
                  dispatch({ type: type.order.create.success, res3 });
                  successCallback();
                })
                .catch((err) => {
                  dispatch({
                    type: type.order.create.fail,
                    payload: err
                  });
                });
            }
          );
        }
      })
      .catch((fail) => {
        dispatch({ type: type.order.create.fail, payload: fail });
      });

  };
};

export default createOrder;
