import type from "../actionType";

const signOut = (successCallback) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signOut()
      .then(() => {
        
        successCallback();
        dispatch({ type: type.auth.signOut.success });
      })
      .catch((err) => {
        dispatch({ type: type.auth.signOut.fail, payload: err });
      });
  };
};

export default signOut;
