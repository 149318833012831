import type from "../actionType";

//  TODO Needs a check.
const offerDelete = (offerid:string) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    if (
      (window as any).confirm(
        `Are you sure you want to delete this offer? This option cannot be undone!`
      )
    ) {
      const firestore = getFirestore();
      firestore
        .collection('offers')
        .doc(offerid)
        .delete()
        .then(() => {
          dispatch({ type: type.admin.offers.delete.success });
        })
        .catch((err) => {
          dispatch({ type: type.admin.offers.delete.fail, payload: err });
        });
    }
  };
};

export default offerDelete;
