const socialMedia = [
    {
        platform: "facebook",
        url: "https://www.facebook.com/twzzr.in/",
      },
      {
        platform: "twitter",
        url: "https://www.twitter.com/twzzr",
      },
      {
        platform: "instagram",
        url: "https://www.instagram.com/twzzr.in/",
      }
];

export default socialMedia;