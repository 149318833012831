import axios from 'axios';
import emailService from '../../services/email.js';
import variables from '../../var.js';
import type from '../actionType';

export interface signUpInterface {
  accountType: string,
  db: string,
  email: string,
  firstName: string,
  lastName: string,
  fullRegistrationDone: boolean,
  madeAt: Date,
  mobile: string,
  pincode: string,
  address: string,
  password: string
}

const signUp = (newUser:signUpInterface, getEnvironment:boolean, successCallback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        firestore
          .collection('users')
          .doc(resp.user.uid)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            email: newUser.email,
            mobile: newUser.mobile,
            shop: newUser.address,
            pincode: newUser.pincode,
            accountType: newUser.accountType,
            madeAt: new Date(),
            db: getEnvironment
          })
          .then(() => {
            const apiAuth = variables.NodeJS.apiKey;
            const firstName = `${newUser.firstName  } ${  newUser.lastName}`;
            const email= newUser.email;
            emailService({
              firstName,
              email,
              tag: "USER_JOIN",
              apiAuth
            }).then((res) => {
                successCallback();
                dispatch({ type: type.auth.signUp.success });
              })
              .catch((erx) => {
                console.error(erx);
                dispatch({
                  type: type.auth.signUp.fail,
                  payload: 'Failed to send the email'
                });
              });
          })
          .catch((err) => {
            dispatch({
              type: type.auth.signUp.fail,
              payload: 'Failed to register an account in the database'
            });
          });
      })
      .catch((err) => {
        dispatch({
          type: type.auth.signUp.fail,
          payload: 'Failed to make an account.'
        });
      });
  };
};

export default signUp;
