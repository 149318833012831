import type from "../actionType";

const linkNumber = (phoneNumber:string) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    phoneNumber = `+91${  phoneNumber}`;
    const firebase = getFirebase();
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        defaultCountry: 'IN'
      }
    );

    
    const user = firebase.auth().currentUser;
    user
      .linkWithPhoneNumber(phoneNumber, (window as any).recaptchaVerifier)
      .then((confirmationResult) => {
        let value;
        do {
          value = prompt('Enter OTP');
          console.log(value);
          /*
          TODO Check what that is isNeditProfileAn function all about:
          while (!value || isNeditProfileaN(parseInt(value)))

          For now I've removed it from the while loop below
          */ 
        } while (!value);
        confirmationResult
          .confirm(value)
          .then((res) => {
            dispatch({ type: type.profile.link.success });
          })
          .catch((err) => {
            dispatch({ type: type.profile.link.fail, payload: err });
          });
      })
      .catch((error) => {
        dispatch({ type: type.profile.link.fail, payload: error });
      });
  };
};
export default linkNumber;
