import type from "../actionType";

const deleteOrder = (quoteId:string) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      if (
        (window as any).confirm(
          `Are you sure you want to delete this order (id ${quoteId})? This option cannot be undone!`
        )
      ) {
        firestore
          .collection("quotes")
          .doc(quoteId)
          .delete()
          .then((res) => {
            console.log("Successfully Deleted");
            dispatch({ type: type.order.delete.success, res });
          })
          .catch((error) => {
            console.error("Delete Order", error);
            dispatch({ type: type.order.delete.fail, error });
          });
      }
    };
  };

  export default deleteOrder;