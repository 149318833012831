const trendingServices = [
    {
        name: "Ride on Car/Bike",
        image: "services-ford-ranger.png"
    },
    {
        name: "Drone/ Helicopter",
        image: "services-drone.png"
    },
    {
        name: "RC Car",
        image: "services-rc.jpg"
    },
    {
        name: "Other",
        image: "services-piano.jpg"
    }
];

export default trendingServices;