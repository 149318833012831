import axios from "axios";

const slackMessage = (webhookUrl: string, messageBody: string) => {
    return axios.post(webhookUrl, { "body": messageBody }, {
        headers: {
            Accept: "application/json, text/plain, */*",
        }
    }).catch((error) => console.error(error));
};
export default slackMessage;

