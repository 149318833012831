import fullRedux from './logic/redux/redux';
import variables from './logic/var';
import emailService from './logic/services/email';
import billGenerator from './logic/services/billGenerator';
import pincodeCheckService from './logic/services/pincodes';
import slackMessage from './logic/services/slackMessage';
import OrderStatusMgr from './logic/managers/orderStatus';
import SearchMgr from './logic/managers/search';
import makersMania from './logic/services/makersMania';

const { reduxActions, reduxTypes } = fullRedux;
const services = {
    billGenerator,
    emailService,
    pincodesCheck: pincodeCheckService,
    slackMessage, makersMania
};
const managers={
    OrderStatusMgr,
    SearchMgr
};
export {
    reduxActions,
    reduxTypes,
    variables,
    services,
    managers
};
export default {
    reduxActions,
    reduxTypes,
    variables,
    services,
    managers
};