import pickupPts from "./var/pickupPts";
import trendingServices from "./var/trendingServices";
import socialMedia from "./var/socialMedia";
import { faqs, benefits, servicesOffered} from "./var/services";

const variables = {
  AdminKey: 'gVmC69Vb8zgOCZ7vicGE6VgnHZf1',
  NodeJS: {
    apiKey: '4aaa4f8081fe965d83b7ad1fb51133c959a8c92b43a3a020e3c7542f6a454818',
    apiUrl: 'https://twzzrapi.herokuapp.com'
  },
  firebaseCredentials: {
    apiKey: 'AIzaSyA6dUWtyrz1cQd1sMZqRCLLFWci1IcW4wQ',
    authDomain: 'tweezer-4f5cd.firebaseapp.com',
    databaseURL: 'https://tweezer-4f5cd.firebaseio.com',
    projectId: 'tweezer-4f5cd',
    storageBucket: 'tweezer-4f5cd.appspot.com',
    messagingSenderId: '783592476616',
    appId: '1:783592476616:web:79a5f36e33283c6b573afd',
    measurementId: 'G-BEQ87MGLQJ'
  },
  pickupPts,
  trendingServices,
  socialMedia,
  services: {
    benefits,
    faqs,
    servicesOffered
  }
};
export default variables;
