import axios from 'axios';
import pincodeCheckService from '../../services/pincodes';
import type from '../actionType';

const addPincode = (pincode:string, getEnvironment:boolean) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    pincodeCheckService(pincode).then((res) => {
      if (res.data[0].Status === 'Success') {
        firestore
          .collection('servicingPincodes')
          .doc(pincode)
          .set({
            db: getEnvironment,
            location:
              `${res.data[0].PostOffice[0].Block 
              }, ${ 
              res.data[0].PostOffice[0].District 
              } - ${ 
              res.data[0].PostOffice[0].State}`
          })
          .then(() => {
            dispatch({ type: type.admin.pincodes.create.success });
          })
          .catch((err) => {
            dispatch({
              type: type.admin.pincodes.create.fail,
              payload: err
            });
          });
      } else {
        dispatch({
          type: type.admin.pincodes.create.fail,
          payload: 'Sorry, that was an invalid pincode.'
        });
      }
    });
  };
};

export default addPincode;
