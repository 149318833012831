import { ExtendedFirestoreInstance } from "react-redux-firebase";
import type from "../actionType";

const techniciansAssign = (userId:string, fullName:string, getEnvironment:boolean) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore: ExtendedFirestoreInstance = getFirestore();
    const batch = firestore.batch();
    batch.set(firestore.collection('technicians').doc(userId), {
      db: getEnvironment,
      name: fullName
    });
    batch.update(firestore.collection('users').doc(userId), {
      accountType: 'Technician'
    });
    batch
      .commit()
      .then((res) => {
        dispatch({ type: type.admin.technicians.assign.success });
      })
      .catch((err) => {
        dispatch({
          type: type.admin.technicians.assign.fail,
          payload: err
        });
      });
  };
};

export default techniciansAssign;
