import type from "../actionType";

const verifyOtp = (mobileNum:string, otpInput, getEnvironment:boolean) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const optConfirm = (window as any).confirmationResult;
    const firestore = getFirestore();
    if (optConfirm) {
      optConfirm
        .confirm(otpInput)
        .then((result) => {
          // Successful signup
          console.log(result);
          if (result.user.uid !== null) {
            console.log('Successful attempt', result.user.uid);
            const userRef = firestore.collection('users').doc(result.user.uid);
            firestore
              .runTransaction((transaction) => {
                return transaction.get(userRef).then((refDoc) => {
                  if (!refDoc.exists) {
                    return transaction.set(userRef, {
                      mobile: mobileNum,
                      madeAt: new Date(),
                      db: getEnvironment,
                      fullRegistrationDone: false
                    });
                  } 
                    return refDoc;
                  
                });
              })
              .then(() => {
                return dispatch({ type: type.auth.otp.verify.success });
              })
              .catch((err) => {
                dispatch({
                  type: type.auth.otp.verify.fail,
                  payload: err
                });
              });
          } else {
            dispatch({
              type: type.auth.otp.verify.wrongOtp,
              payload: 'Wrong OTP!'
            });
          }
        })
        .catch((error) => {
          dispatch({ type: type.auth.otp.verify.fail, payload: error });
        });
    } else {
      dispatch({
        type: type.auth.otp.verify.fail,
        payload: 'OTP Faced Error!'
      });
    }
  };
};

export default verifyOtp;
