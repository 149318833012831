interface StageInformation {
  icon: string
  class: string
  tag: string
  description: string
  messageMandatory?: boolean
  stage: string
}

export default class OrderStatusMgr {
  static stages = {
    ORDER_PLACED: {
      icon: 'edit-2',
      class: 'primary',
      tag: 'Order Placed',
      description: 'Your order has been registered with us.'
    },
    PICKED: {
      icon: 'package',
      class: 'teal',
      tag: 'Pickup/Visit Scheduled',
      description: 'A pickup/ visit has been scheduled.'
    },
    QUOTATION: {
      icon: 'printer',
      class: 'purple',
      tag: 'Quotation Generated',
      description: 'A quotation has been generated for your item.'
    },
    IN_PROCESS: {
      icon: 'cpu',
      class: 'orange',
      tag: 'In Process',
      description:
        "You've accepted the total charges, and work on your order has begun."
    },
    DELIVERED: {
      icon: 'check',
      class: 'success',
      tag: 'Delivered',
      description: 'Your order has been delivered. Thank you!'
    },
    CANCELLED: {
      icon: 'x-square',
      class: 'danger',
      tag: 'Cancelled',
      messageMandatory: true,
      description: 'Your order has been cancelled. Sorry!'
    },
    FROZEN: {
      icon: 'pause',
      class: 'info',
      tag: 'Frozen',
      messageMandatory: true,
      description: 'Your order has been frozen. Sorry!'
    }
  }
  _type: any

  constructor(type: string) {
    type = OrderStatusMgr.verify(type)
    if (type) {
      this._type = type
    }
  }

  /** Get full inforation about a given stage. */
  info(
    status: keyof typeof OrderStatusMgr.stages = this._type
  ): StageInformation {
    status = OrderStatusMgr.verify(status)
    if (Object.keys(OrderStatusMgr.stages).includes(status)) {
      return { stage: status, ...OrderStatusMgr.stages[status] }
    }
    return {
      stage: '',
      icon: 'flag',
      class: 'dark',
      tag: '',
      description: ''
    }
  }

  /** Get information about the first order stage. */
  static getFirstStage(): keyof typeof OrderStatusMgr.stages {
    return 'ORDER_PLACED'
  }

  /** Get information about the last order stage. */
  static getLastStage(): keyof typeof OrderStatusMgr.stages {
    return 'DELIVERED'
  }

  /** Mark order as cancelled. */
  static getCancelledStage() {
    // orderDetailsPage:347
    return 'CANCELLED'
  }

  static verify(status: any): keyof typeof OrderStatusMgr.stages {
    if (status === 'ON_THE_WAY') {
      return OrderStatusMgr.getLastStage()
    }
    // TODO Run some kind of check to verify order status
    return status;
  }

  static getByStatusCategory(
    statustype: 'completed' | 'cancelled' | 'incomplete' | 'not_cancelled'
  ): string[] {
    switch (statustype) {
      case 'completed':
        // orderAnalysis:88
        return [this.getLastStage()]
      case 'not_cancelled':
        return [this.getFirstStage(), "PICKED", "QUOTATION", "IN_PROCESS", this.getLastStage()]
      case 'cancelled':
        // orderAnalysis:93
        return [this.getCancelledStage(), 'FROZEN']
      case 'incomplete':
        // assignOrder:25
        // updateInventoryPage:361
        return ['QUOTATION', 'IN_PROCESS']
    }
    return []
  }

  /** Given a stage, get next stage possible, excluding the last stage and stages like CANCELLED or FROZEN */
  nextInfo(status = this._type): StageInformation {
    if (['DELIVERED', 'CANCELLED', 'FROZEN'].includes(status)) {
      throw Error(
        `Next order status cannot be computed for an order already at ${status}`
      )
    }

    const next = Object.keys(OrderStatusMgr.stages)[
      Object.keys(OrderStatusMgr.stages).indexOf(status) + 1
    ]
    this._type = next
    return this.info()
  }

  /** Given a stage, get last stage possible, excluding the first stage and stages like CANCELLED or FROZEN */
  previousInfo(status = this._type) {
    if (['ORDER_PLACED', 'CANCELLED', 'FROZEN'].includes(status)) {
      throw Error(
        `Previous order status cannot be computed for an order already at ${status}`
      )
    }

    const previous = Object.keys(OrderStatusMgr.stages)[
      Object.keys(OrderStatusMgr.stages).indexOf(status) - 1
    ]
    this._type = previous
    return this.info()
  }
}
