import type from "../actionType";

export interface editProfileInterface {
  firstname: string,
  lastname: string,
  email: string,
  accountType: string,
  phoneNumber: string,
  address: string,
  pincode: string
}
const editProfile = (profile: editProfileInterface, password: string | undefined) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const authId = getState().firebase.auth.uid;
    if (password) {
      profile["fullRegistrationDone"] = true;
    }
    firestore
      .collection("users")
      .doc(authId)
      .update(profile)
      .then((res) => {
        if (password) {
          const credential = firebase.auth.EmailAuthProvider.credential(profile.email, password);
          const user = firebase.auth().currentUser;
          user
            .linkWithCredential(credential)
            .then((usercred) => {
              console.log("Successful Link");
              dispatch({ type: type.profile.link.success });
            })
            .catch((err1) => {
              console.log("Account linking error", err1);
              // TODO Handle Firebase errors which say "user needs to re-log in"
              dispatch({ type: type.profile.link.fail, payload: err1 });
            });
        } else {
          dispatch({ type: type.profile.edit.success, res });
        }
      })
      .catch((err) => {
        dispatch({ type: type.profile.edit.fail, payload: err });
      });
  };
};
export default editProfile;