import type from "../actionType";

export interface offersInterface{
  body: string,
  changed: Date,
  db: string,
  title: string,
  editMode: boolean
}

const offersAddUpdate = (offer: offersInterface, getEnvironment:boolean) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    if (offer.editMode === false) {
      firestore
        .collection('offers')
        .add({
          title: offer.title,
          body: offer.body,
          changed: new Date(),
          db: getEnvironment
        })
        .then(() => {
          dispatch({ type: type.admin.offers.create.success });
        })
        .catch((err) => {
          dispatch({ type: type.admin.offers.create.fail, payload: err });
        });
    } else {
      firestore
        .collection('offers')
        .doc(offer.editMode)
        .update({
          title: offer.title,
          body: offer.body,
          changed: new Date()
        })
        .then(() => {
          dispatch({ type: type.admin.offers.update.success });
        })
        .catch((err) => {
          dispatch({ type: type.admin.offers.update.fail, payload: err });
        });
    }
  };
};

export default offersAddUpdate;