interface SearchResults {
  item: any
  itemName: string
  label: string
  index: any
}

/**
 * param {any[]} ignoreFields (optional, default []) Any 'itemName's to ignore from search list.
 * This can include 'db', which is mostly common pan-Twizzr
 * param {any} include (optional, default false for all) Configuration options to enable
 * or disable numbers, empty strings, URLs and booleans from result.
 */

/**
 * Twizzr-wide solution to compute search results.
 */
export default class SearchMgr {
  _searchParams: SearchResults[] = []
  _ignoreFields: any[]
  _includeNumbers: boolean
  _includeEmpty: boolean
  _includeURL: boolean
  _includeBooleans: boolean

  constructor(
    ignoreFields: string[] = [],
    include: {
      numbers: boolean
      empty: boolean
      URL: boolean
      booleans: boolean
    } = {
      numbers: false,
      empty: false,
      URL: false,
      booleans: false
    }
  ) {
    this._ignoreFields = ignoreFields
    this._includeNumbers = include.numbers
    this._includeEmpty = include.empty
    this._includeURL = include.URL
    this._includeBooleans = include.booleans
  }

  /**
   * Add and streamline data into a single form.
   * @param type A label to associate this data with
   * @param arr Data (in array form)
   * @param getIndex A function that returns the index of element.
   * @returns An instance of the same object
   */
  addResults(type: string, arr: any[], getIndex): this {
    const isValidURL = (url: string) => {
      try {
        const temp = new URL(url)
        return true
      } catch (e) {
        return false
      }
    }

    const isValidNumber = (str) => {
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ) // ...and ensure strings of whitespace fail
    }

    const arrayIterator = (element, itemName: string, id) => {
      if (!this._ignoreFields.includes(itemName) && element !== id) {
        if (typeof element === 'object' && Array.isArray(element)) {
          element.forEach((e) => arrayIterator(e, '', id))
        }
        if (
          typeof element === 'object' &&
          !Array.isArray(element) &&
          element !== null
        ) {
          //   INCOMPLLETE
          Object.keys(element).forEach((e) => arrayIterator(element[e], e, id))
        }

        if (
          typeof element === 'string' &&
          element !== '' &&
          !isValidNumber(element)
        ) {
          if (!this._includeURL) {
            if (!isValidURL(element)) {
              this._searchParams.push({
                item: element,
                itemName,
                label: type,
                index: id
              })
            }
          } else {
            this._searchParams.push({
              item: element,
              itemName,
              label: type,
              index: id
            })
          }
        }

        if (isValidNumber(element) && this._includeNumbers) {
          this._searchParams.push({
            item: element,
            itemName,
            label: type,
            index: id
          })
        }
      }
    }

    if (Array.isArray(arr)) {
      arr.forEach((e) => {
        arrayIterator(e, '', getIndex(e))
      })
    }

    return this
  }

  /**
   * Get all unique keys stored within the model
   * @param [upperCase=false] Set true to convert keys from camelCase to Sentence Case
   */
  getKeys(upperCase: boolean = false): string[] {
    return [
      ...new Set(
        this._searchParams.map((e) => {
          if (upperCase) {
            const result = e.itemName.replace(/([A-Z]{1,})/g, ' $1')
            return result.charAt(0).toUpperCase() + result.slice(1)
          }
          return e.itemName
        })
      )
    ]
  }

  /**
   * Get all data corresponding to the given key 
   * @param key The `itemName` to filter for
   * @returns array of data stored corresponding to the given key
   */
  getByKey(key: string): any[] {
    return this._searchParams
      .filter((e) => e.itemName === key)
      .map((e) => e.item)
  }

  /**
   * Get search results for a given query
   */
  search(searchQuery: string): SearchResults[] {
    return this._searchParams.filter((d) => {
      if (d.item.toLowerCase().includes(searchQuery)) {
        return d
      }
      return null
    })
  }
}