import type from "../actionType";

export interface deleteAdminIMSInterface{
  componentName: string,
  uid: string
}
const deleteAdminIMS = (component:deleteAdminIMSInterface) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    if (
      (window as any).confirm(
        `Are you sure you want to delete this inventory item '${component.componentName}'? This option cannot be undone!`
      )
    ) {
      firestore
        .collection('inventory')
        .doc(component.uid)
        .delete()
        .then((res) => {
          console.log('Successfully Deleted');
          dispatch({ type: type.ims.admin.delete.success, res });
        })
        .catch((error) => {
          dispatch({ type: type.ims.admin.delete.fail, payload: error });
        });
    }
  };
};

export default deleteAdminIMS;