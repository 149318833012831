import type from "../actionType";

export interface inventoryInterface{
  componentCategory: string,
  componentDesc: string,
  componentName: string,
  componentPrice: number,
  componentQty: number,
  db: string,
  mode: string,
  lastusername: string,
  timestamp: Date,
  uid:number,
  
}

const createAdminIMS = (component:inventoryInterface, getEnvironment:boolean) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const profile = getState().firebase.profile;
    const objToAdd = {
      db: getEnvironment,
      componentName: component.componentName,
      componentQty: component.componentQty,
      lastmode: component.mode,
      componentCategory: component.componentCategory,
      componentDesc: component.componentDesc,
      componentPrice: component.componentPrice,
      lastusername: `${profile.firstName  } ${  profile.lastName}`,
      timestamp: new Date()
    };
    const firestore = getFirestore();
    if (component.uid !== -1) {
      firestore
        .collection('inventory')
        .doc(component.uid)
        .set(objToAdd)
        .then(() => {
          dispatch({ type: type.ims.admin.update.success });
        })
        .catch((err) => {
          dispatch({ type: type.ims.admin.update.fail, payload: err });
        });
    } else {
      firestore
        .collection('inventory')
        .add(objToAdd)
        .then(() => {
          dispatch({ type: type.ims.admin.create.success });
        })
        .catch((err) => {
          dispatch({ type: type.ims.admin.create.fail, payload: err });
        });
    }
  };
};

export default createAdminIMS;