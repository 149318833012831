import { ExtendedFirebaseInstance } from "react-redux-firebase";
import type from "../actionType";

const signInWithProvider = (providerId,successCallback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase: ExtendedFirebaseInstance = getFirebase();
    firebase
      .auth()
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(() => {
        console.log('Successfull signup with Provider!');

        successCallback();
        dispatch({ type: type.auth.withProvider.success });
      })
      .catch((error) => {
        // An error happened.
        if (error.code === 'auth/account-exists-with-different-credential') {
          const email = error.email;

          firebase
            .auth()
            .fetchSignInMethodsForEmail(email)
            .then((methods) => {
              console.log(error, methods);
              dispatch({ type: type.auth.withProvider.relogin });
            });
        } else {
          dispatch({ type: type.auth.withProvider.fail, payload: error });
        }
      });
  };
};

export default signInWithProvider;