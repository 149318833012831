import type from "../actionType";

export interface ancExpensesAddUpdateInterface{
  amount: number,
  createdAt: Date,
  db: string,
  title: string
  editMode: boolean
}

const ancExpensesAddUpdate = (expense: ancExpensesAddUpdateInterface, getEnvironment:boolean) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    if (expense.editMode === false) {
      firestore
        .collection('ancillaryExpenses')
        .add({
          title: expense.title,
          amount: expense.amount,
          createdAt: new Date(),
          db: getEnvironment
        })
        .then(() => {
          dispatch({ type: type.admin.ancexpenses.create.success });
        })
        .catch((err) => {
          dispatch({
            type: type.admin.ancexpenses.create.fail,
            payload: err
          });
        });
    } else {
      firestore
        .collection('ancillaryExpenses')
        .doc(expense.editMode)
        .update({
          title: expense.title,
          amount: expense.amount
        })
        .then(() => {
          dispatch({ type: type.admin.ancexpenses.update.success });
        })
        .catch((err) => {
          dispatch({
            type: type.admin.ancexpenses.update.fail,
            payload: err
          });
        });
    }
  };
};

export default ancExpensesAddUpdate;