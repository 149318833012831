import type from "../actionType";

export interface techniciansAllotInterface{
  db: string,
  name: string,
  id: string
}

const techniciansAllot = (orderId:string, technician:techniciansAllotInterface) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      firestore
        .collection("quotes")
        .doc(orderId)
        .update({
          allotedTo: technician.id,
          lastChanged: new Date()
        })
        .then(() => {
          dispatch({
            type: type.admin.technicians.allot.success,
            payload: { id: orderId, technician: technician.name },
          });
        })
        .catch((err) => {
          dispatch({ type: type.admin.technicians.allot.fail, payload: err });
        });
    };
  };

  export default techniciansAllot;