import type from "../actionType";

const sendOtp = (phoneNumber:string, callback) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('Recaptcha Setup');
    const firebase = getFirebase();
    (window as any).recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        defaultCountry: 'IN'
      }
    );
    const appVerifier = (window as any).recaptchaVerifier;
    // TODO Check if phoneNumber already starts with +91
    phoneNumber = `+91${  phoneNumber}`;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        (window as any).confirmationResult = confirmationResult;
        console.log('OTP is sent', callback);
        callback && callback();
        dispatch({ type: type.auth.otp.send.success });
      })
      .catch((error) => {
        dispatch({ type: type.auth.otp.send.fail, payload: error });
      });
  };
};
export default sendOtp;
