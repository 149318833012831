import dayjs from "dayjs";
import { orderItems } from "../../interfaces.js";
import OrderStatusMgr from "../../managers/orderStatus.js";
import emailService from "../../services/email.js";
import variables from "../../var.js";
import type from "../actionType";

export interface UpgradeOrderInterface{
  authId: number,
  billNum: number | undefined,
  createdAt: Date,
  db: string,
  email: string,
  error:string,
  firstName: string,
  image: string,
  items: orderItems[],
  lastName: number,
  message: string,
  mobile: string,
  onBehalfOf: boolean,
  orderID: string,
  product: string,
  quantity: string,
  shop: string,
  status: string,
  targetDate: Date,
  technicianName: string | null,
  id: string,
  sendEmail: boolean
}

const UpgradeOrder = (order:UpgradeOrderInterface) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      const profile = getState().firebase.profile;
  
      firestore
        .collection("quotes")
        .doc(order.id)
        .update({
          items: order.items,
          message: order.message || "",
          status: OrderStatusMgr.verify(order.status),
          targetDate: order.targetDate || null,
          lastChanged: new Date()
        })
        .then((res) => {
          if (order.sendEmail) {
            const apiAuth = variables.NodeJS.apiKey;
            const firstName = order.firstName;
            const email = order.email;
            const orderStatus = order.status;
            const itemsList = order.items;
            const orderMessage = order.message;
            const orderDate = order.targetDate;
            const billId = order.id;
            const createdAt = dayjs(order.createdAt["seconds"] * 1000).format("MMM D YYYY");
            emailService({
              firstName,
              email,
              tag: "ORDER_UPDATE",
              apiAuth,
              orderStatus,
              orderMessage,
              billId,
              orderDate,
              itemsList,
              createdAt,
            }).then((res2) => {
                
                dispatch({ type: type.order.update.success });
              })
              .catch((err2) => {
                console.warn(
                  "Success! The order has been updated, but for technical reasons, the email couldnt be sent to the user."
                );
                dispatch({
                  type: type.order.update.fail,
                  error: "The order has been updated, but the email could not be sent",
                  payload: err2,
                });
              });
          } else {
            console.log("Success! The order has been updated, and no email is sent to the user.");
            dispatch({ type: type.order.update.success });
          }
        })
        .catch((err) => {
          console.error(
            "Error! Couldn't update the order. Please contact the developer team for support. Apologies!"
          );
          dispatch({
            type: type.order.update.fail,
            error: "Couldn't update the order",
            payload: err,
          });
        });
    };
  };
export default UpgradeOrder;  