import axios from "axios";
import type from "../actionType";

const tutorialsAuth = (userdata:any,callback) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        const url = "https://sheet.best/api/sheets/795f488b-4e45-4616-906b-0b995528fe87";
        axios.post(url,userdata).then((res)=>{
            callback();
            dispatch(type.auth.tutorialsAuth.success);
        }).catch((err)=>{
            dispatch(type.auth.tutorialsAuth.fail);
        });
    };
};

export default tutorialsAuth;