import axios from "axios";
import { orderItems } from "../interfaces";
import variables from "../var";

export interface emailServiceInterface {
  email: string,
  firstName: string,
  tag: "USER_JOIN" | "ORDER_UPDATE",
  apiAuth: string,
  billId?: string,
  orderStatus?: string,
  orderMessage?: string,
  orderDate?: Date,
  itemsList?: orderItems[],
  createdAt?: string
}
const emailService = (body: emailServiceInterface) => {
  console.log("Sending email to ", body.email);
  return new Promise((resolve, reject) => {
    axios.post(`${variables.NodeJS.apiUrl}/mail`, body);
  });
};

export default emailService;