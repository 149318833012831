const pickupPts = [
    {
        name: "Saint Anthony School Road, 1st Cross, Near Holy Family Church, Ramamurthy Nagar, Bangalore",
        pincode: 560016,
        type: "HEAD"
    },
    {
        name: "Shop No 138, 29th Main Road, Dollar Layout, Stage 2, BTM Layout, Bangalore",
        pincode: 560076
    }
];

export default pickupPts;