import type from "../actionType";

const ancExpensesDelete = (expId:string) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    if (
      (window as any).confirm(
        `Are you sure you want to delete this record? This option cannot be undone!`
      )
    ) {
      const firestore = getFirestore();
      firestore
        .collection('ancillaryExpenses')
        .doc(expId)
        .delete()
        .then(() => {
          dispatch({ type: type.admin.ancexpenses.delete.success });
        })
        .catch((err) => {
          dispatch({
            type: type.admin.ancexpenses.delete.fail,
            payload: err
          });
        });
    }
  };
};


export default ancExpensesDelete;